/**
 * 
 */
import { mode, pad, enc, AES } from 'crypto-js';

const aesMeetingDecipher = (cipherString) => {
    const key = process.env.REACT_APP_CRYPTO_MEETING_HASH_KEY;
    const iv =  process.env.REACT_APP_CRYPTO_16B_INIT_VECTOR;
    const decipher = AES.decrypt(cipherString, enc.Base64.parse(key), {   
        iv: enc.Base64.parse(iv),
        mode: mode.CBC,
        padding: pad.Pkcs7,
        keySize: 256
    })
    return decipher.toString(enc.Utf8)
}

export{
    aesMeetingDecipher
}
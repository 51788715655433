import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from '@mui/material';
import { useEffect, useState, memo } from 'react';
import './timer.css'

const Timer = ({ finalDate, inSeconds=false, onTime=()=>{}, ...props }) => {
    const { t } = useTranslation();
    const [clockTime, setClockTime] = useState(undefined);

    const fromMillisecondsToTimer= (milliseconds, inSeconds) => {
        let timer;
        let seconds = Math.max(0, Math.floor(milliseconds / 1000));
        if(inSeconds){
            timer = seconds.toString();
        }else{
            let min = seconds / 60 | 0; // truncate and cast the float
            seconds = seconds % 60 | 0; // truncate and cast the float
            let formatedSeconds = `${seconds >= 10 ? '': '0'}${seconds}`;
            timer = `${min}:${formatedSeconds}`;
        }
        return timer 
    }

    useEffect(() => {
        if(finalDate != null && finalDate instanceof Date){
            const id = setInterval(() => {
                const now = new Date();
                const remainingTime = finalDate.getTime() - now.getTime();
                setClockTime(fromMillisecondsToTimer(remainingTime, inSeconds));
                if(remainingTime <= 0){
                    console.log('on time')
                    onTime();
                    clearInterval(id);
                }
            }, 1000)
            return(()=>{
                clearInterval(id);
            })
        }
    }, [finalDate])

    return (
            <div {...props} >
                {clockTime ?
                        <Typography variant='body2'>
                            {`${clockTime} ${inSeconds ? t('secondAbbr') : t('minuteAbbr')}`}
                        </Typography>
                    :
                        <Skeleton variant='text' width={"40px"}/>
                }   
            </div>
    )
}

export default Timer
import { useState, useEffect } from 'react'
import { mode, pad, enc, AES } from 'crypto-js';
import { isAValidMeetingProvider } from '../../aux/meetingProviderHelpers'
import { getMeetingJoinParams } from '../../services/meetingServices'
import ButtonLoading from '../ButtonLoading/ButtonLoading'
import './joinMeetingDesktopAppButton.css'
import { aesMeetingDecipher } from '../../aux/cryptoHelpers';

const JoinMeetingDesktopAppButton = ({ meetingProviderName, meetingParams, label='join with app', onError=()=>{}, ...props}) => {
    const [showButton, setShowButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const handleOnClick = async() => {
        try{
            setIsLoading(true);
            throw new Error();
            const joinUrl = aesMeetingDecipher(meetingParams.cipheredJoinUrl)
            window.location.assign(joinUrl);
        }catch(error){
            onError(error);
        }finally{
            setIsLoading(false);
        }
    }
    useEffect(() => {
        setShowButton(isAValidMeetingProvider(meetingProviderName))
    }, [meetingProviderName])
    return (
        showButton &&
            <ButtonLoading 
                onClick={handleOnClick} 
                label={label} 
                isLoading={isLoading || props.isLoading}
                disabled={props.disabled} 
                {...props} 
            />
    )
}

export default JoinMeetingDesktopAppButton
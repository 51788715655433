/**
 * This module containes useful and redundant API-authorization-related functions
 */
import jwt_decode from "jwt-decode";
import { sessionVariables } from "./sessionHelpers";

function isTokenActive(token){
    try{
        const currentTime = new Date().getTime();
        const tokenPayload = jwt_decode(token);
        const tokenExpTime = tokenPayload.exp  * 1000
        return currentTime < tokenExpTime;
    }catch(err){
        throw err;
    }
}

/**
 * @param {*} token 
 * @returns  exp in ms 
 */
function getTokenExp(token){
    try{
        const tokenPayload = jwt_decode(token);
        return tokenPayload.exp * 1000
    }catch(err){
        throw err;
    }
}

function removeAuthTokensOnClient(){
    sessionVariables.remove('accessToken');
    sessionVariables.remove('refreshToken');
}

export { 
        isTokenActive, 
        removeAuthTokensOnClient,
        getTokenExp
    }
import { useState, useEffect, forwardRef } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/system';
// Style
import './buttonLoading.css';


const ButtonLoading = ({isLoading, label, disabled=false, ...props}, ref) => {
    const theme = useTheme();
    const styles = {
        circularProgress:{
            color: props.hasOwnProperty("color") ? theme.palette[props.color].main: theme.palette.primary.main,
        },
    };
    return (
        <Button ref={ref} disabled={disabled || isLoading} {...props}>
            {label}

            <div className={`button-loading-progress-container-hidden ${isLoading && 'button-loading-progress-container-active'}`}>
                <CircularProgress size="2em" style={styles.circularProgress}  />
            </div>
        </Button>
    )
}

export default forwardRef(ButtonLoading)

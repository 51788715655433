import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { 
    setHistoryForInterceptors, setUpInterceptorsAPIResponseError,
    setForceUserToLoginForInterceptors
} from "../../aux/requestMethods";
import { clearSessionVariables } from "../../aux/sessionHelpers";

const InjectAxiosInterceptors = () => {
    const history = useHistory();
    const API_CUSTOM_ERROR_NAME = 'CustomError';
    
    const forceCancelMeeting = () => {
        // Absorved by logoutUserOnClient on 09/24
        // sessionVariables.remove('accessToken');
        // sessionVariables.remove('refreshToken');
        // setUser(null);
        // const redirectPath = window.location.pathname;
        // const redirectPathParam = 'from'
        // history.push(`/login?${redirectPathParam}=${redirectPath}`); // Previously redirected to app/login
        clearSessionVariables()
        const redirectPath = window.location.pathname;
        const redirectQuery = { 'from': redirectPath };
        redirectToRoute('/login', redirectQuery)
    }

    const redirectToRoute = (routePath, query={}, replaceRoute=false) => {
        const queryParams = new URLSearchParams(query).toString();
        const path = `${routePath}${queryParams ? `?${queryParams}`:''}`;
        const redirect = replaceRoute ? history.replace : history.push;
        redirect(path);
    }

    const handleAPIResponseError = (error) => {
        const isAPICustomError = error.response?.data?.name === API_CUSTOM_ERROR_NAME ? true : false;
        if(isAPICustomError){
            // Known cause
            handleAPIResponseCustomError(error);
        }else{
            // unknown cause
        }
    }

    const handleAPIResponseCustomError = (error) => {
        let forceLogin = false;
        const cause = error.response.data.cause;
        switch(cause){
            case 'sr_500':
                // Concurrent active session
                forceLogin = true;
                break;
            case 'sr_101':
                // Wrong credentials
                forceLogin = true;
                break;
            case 'sr_404':
                // Product not found
                redirectToRoute('/404', {}, true);
            default:
                // Remaining custom errors are handled on the request caller function
        }
        if(forceLogin){
            forceCancelMeeting();
        }
    }

    useEffect(()=>{
        setHistoryForInterceptors(history);
        setUpInterceptorsAPIResponseError(handleAPIResponseError);
        setForceUserToLoginForInterceptors(forceCancelMeeting)
    }, [])
    
    // Not rendering anything
    return null
}

export default InjectAxiosInterceptors




import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationES from './App/i18nLocalTranslation/es-ES/translation.json';

const localResources = {
    "es-ES": {
        translation: translationES
    },
    ...(process.env.NODE_ENV !== 'production' ? 
        {
            "en-US": {
                translation: translationES
            },
        }
        :
        {}
    )
}

const I18N_DEFAULT_LOCALECODE = 'es-ES';

i18n
    .use(initReactI18next)
    .init({
            debug: process.env.NODE_ENV !== 'production', // Comment on development
            fallbackLng: {
                'es': ['es-ES'],
                'en': ['en-US'],
                default: [I18N_DEFAULT_LOCALECODE],
            },
            fallbackNS: 'translation',
            resources: localResources
    });
export default i18n;
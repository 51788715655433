import { useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { textTransform } from '../../aux/aux';
import './toggleErrorMsg.css'

const ToggleErrorMsg = ({ msg='error', showTimeMs=null, onShowTime=()=>{}, ...props }) => {
  const theme = useTheme();
  const styles = {
    icon: {
      padding: '0px 5px',
      display: 'block',
      color: theme.palette.error.main,
      fontSize: '2em'
    },
    msg: {
      color: theme.palette.error.main,
    }
  }
  useEffect(() => {
    if(showTimeMs){
      const timeoutId = setTimeout(onShowTime, showTimeMs);
      return(() => {clearTimeout(timeoutId)})
    }
  },[showTimeMs])

  return (
    <div {...props}>
      <Grid container className='toggle-error-msg-container'>
        <Grid item>
          <ErrorOutlineIcon style={styles.icon} />
        </Grid>
        <Grid item xs>
          <Typography style={styles.msg} variant='body2' >
            {msg}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default ToggleErrorMsg
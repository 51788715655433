/**
 * This module contains all the meeting service-related methods
 */

import { getMeetingSignatureFn, getMeetingJoinParamsFn } from "../aux/meetingQueries";
import { requestMinimumTimeoutWrapper } from "../aux/requestMethods";

async function getMeetingSignature( minimumTimeOut=0, signal=null){
    const requestParameters = {};
    return requestMinimumTimeoutWrapper( getMeetingSignatureFn, requestParameters, minimumTimeOut, signal);
}
async function getMeetingJoinParams( minimumTimeOut=0, signal=null){
    const requestParameters = {};
    return requestMinimumTimeoutWrapper( getMeetingJoinParamsFn, requestParameters, minimumTimeOut, signal);
}

export{
    getMeetingSignature,
    getMeetingJoinParams,
}
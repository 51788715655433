import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppBar, Toolbar, Box } from "@mui/material";
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog'
import PopUpModal from '../PopUpModal/PopUpModal'

import LogoX from "../../img/logo-x-fill.svg";
import LogoXNeg from '../../img/logo-x-neg.svg'

import "./HeaderOnMeeting.css";
import { requestMinimumTimeoutWrapper } from "../../aux/requestMethods";
import { textTransform, toMillisecondsFromTimescale } from "../../aux/aux";


const HeaderOnMeeting = ({ meetingPlayer, postMeetingUrl }) => {
    const HEADER_HEIGHT = 60;
    const POP_UP_WIDTH = 380;
    const TIMEOUT_ERROR_LEAVING_SG = 3;
    const history = useHistory();
    const { t } = useTranslation('translation', { keyPrefix: 'headerOnMeeting' });
    const [hover, setHover ] = useState(false);
    const [isLeaving, setIsLeaving] = useState(false);
    const [isLeavingError, setIsLeavingError] = useState(false);
    const [showCancelMeetingPopup, setShowCancelMeetingPopup] = useState(false);
    const styles = {
        headerContainer:{
            height: `${HEADER_HEIGHT}px`,
            zIndex: 1 // Enough to be on top of zmmtg-root 
        },
        clickable: {
            cursor:'pointer'
        },
        icon: {
            display: 'block'
        }
    }
    const onMouseL = () => {
        setHover(false);
    }
    const onMouseE = () => {
        setHover(true);
    }
    const handleOnClickHome = () => {
        setShowCancelMeetingPopup(true);
    }
    const handleOnClosePopup = () => {
        setShowCancelMeetingPopup(false)
    }
    const handleOnLeaveMeeting = async () => {
        try{
            setIsLeaving(true);
            if(meetingPlayer && meetingPlayer.state){
                await requestMinimumTimeoutWrapper(() => {meetingPlayer.leave()});
            }else{
                // This prevent some possible rerenderings that cause
                // zoom restarts
                await requestMinimumTimeoutWrapper(() => {window.location.assign(postMeetingUrl)});    
            }
            // It is redundant meetingPlayer.leave rediret to postMeetingUrl
            // but sometimes meetingPlayer disconnect 
        }catch(error){
            setIsLeavingError(true);
        }finally{
            setIsLeaving(false);
        }
    }
    useEffect(() => {
        if(isLeavingError){
            const timeoutId = setTimeout(() => {
                window.location.assign(postMeetingUrl)
            }, toMillisecondsFromTimescale('sec', TIMEOUT_ERROR_LEAVING_SG))
            return(() => {
                clearTimeout(timeoutId);
            })
        }
    },[isLeavingError])

    return (
        <>
            <PopUpModal 
                showModal={showCancelMeetingPopup} 
                width={`${POP_UP_WIDTH}px`}
                isDialog={true}
                style={{padding:'20px', overflowY:'hidden'}}
            >
                <SimpleNoModalDialog
                    title={textTransform('title',t('leaveTheMeeting'))}
                    contentText={t('doYouWantToEndTheMeeting')} 
                    onAgree={handleOnLeaveMeeting} 
                    onDisagree={handleOnClosePopup}
                    isLoading={isLeaving}
                    isError={isLeavingError}
                    errorMsg={
                        `${textTransform('title',t('errorLeaving'))}.`
                        +`\n${textTransform('title',t('youAreRedirectedIn'))} ${TIMEOUT_ERROR_LEAVING_SG}\"`
                    }
                />
            </PopUpModal>
            <AppBar color="transparent" elevation={0} style={styles.headerContainer}>
                <Toolbar>
                    <Box 
                        display="flex" 
                        alignItems="center"
                        style={{flexGrow:1}}
                    >
                        <div
                        aria-label="Home"
                        onMouseEnter= {onMouseE}
                        onMouseLeave= {onMouseL}
                        style={styles.clickable}
                        onClick={handleOnClickHome}
                        >
                            {hover ? <LogoXNeg alt="logo" height={30} style={styles.icon} /> : <LogoX alt="logo" height={30} style={styles.icon} />
                        } 
                            
                         </div>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default HeaderOnMeeting
import { Box } from '@mui/system'
import { LinearProgress, CircularProgress } from '@mui/material'

// Style
import './loadingComponent.css'

const LoadingComponent = ({visibleElements='both', circleSize=50, barStyle={}, circleStyle={}, bothStyle={}}) => {
    const showCircle = visibleElements === 'both' || visibleElements === 'circle'
    const showBar = visibleElements === 'both' || visibleElements === 'bar'
    return (
        <>
            { showBar &&
                <Box className='loading-component-bar-progress'>
                    <LinearProgress style={{...bothStyle, ...barStyle}}/>
                </Box>
            }
            {showCircle &&
                <Box className='loading-component-circular-progress'>
                    <CircularProgress size={circleSize} style={{...bothStyle, ...circleStyle}}/>
                </Box>
            }
        </>
    )
}

export default LoadingComponent

import { useState } from "react";
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Box } from "@mui/material";
import MenuIcon from '@mui/icons-material'

import Logo from "../../img/logo-alpha.svg";
import LogoNeg from "../../img/logo-alpha-neg.svg";

import "./HeaderSimple.css";
import { useTheme } from "@emotion/react";


const HeaderSimple = ({ onRedirect=()=>{} }) => {
    const theme = useTheme();
    const HEADER_HEIGHT = 60;
    const styles = {
        headerContainer:{
            height: `${HEADER_HEIGHT}px`,
            top: '0px',
            backgroundColor: theme.palette.common.white
        },
        clickable: {
            cursor:'pointer'
        },
        icon: {
            display: 'block'
        }
    }

    const [hover, setHover ] = useState(false)

    const onMouseL = () => {
        setHover(false);
    }
    const onMouseE = () => {
        setHover(true);
    }
    const handleOnClickHome = (e) => {
        onRedirect(e)
    }

    return (
            <AppBar color="transparent" style={styles.headerContainer} elevation={0} position="sticky">
                <Toolbar>
                    <Box 
                        display="flex" 
                        alignItems="center"
                        style={{flexGrow:1}}
                    >
                        <div
                        id='menu-home-btn'
                        onClick={handleOnClickHome}
                        aria-label="Home"
                        onMouseEnter= {onMouseE}
                        onMouseLeave= {onMouseL}
                        style={styles.clickable}
                        >
                            {hover ? <LogoNeg alt="logo" height={30} style={styles.icon} /> : <Logo alt="logo" height={30} style={styles.icon}/>
                        } 
                            
                        </div>
                    </Box>
                </Toolbar>
            </AppBar>
    )
}

export default HeaderSimple
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Divider, Button } from '@mui/material';

import { getMeetingJoinParams } from '../../services/meetingServices';
import { MeetingPlayer } from '../../aux/meetingHelpers';
import { sessionVariables } from '../../aux/sessionHelpers';
import { notifyMainAppWindow } from '../../aux/mainAppCommunicationHelpers';
import { getMeetingProviderOrNull } from '../../aux/meetingProviderHelpers';
import { getTokenExp } from '../../aux/authHelpers';
import { textTransform, toMillisecondsFromTimescale } from '../../aux/aux';

import HeaderSimple from '../../components/HeaderSimple/HeaderSimple';
import JoinMeetingButton from '../../components/JoinMeetingButton/JoinMeetingButton';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import JoinMeetingInstructions from '../../components/JoinMeetingInstructions/JoinMeetingInstructions';
import JoinMeetingDesktopAppButton from '../../components/JoinMeetingDesktopAppButton/JoinMeetingDesktopAppButton';
import HeaderOnMeeting from '../../components/HeaderOnMeeting/HeaderOnMeeting';
import JoinMeetingError from '../../components/JoinMeetingError/JoinMeetingError';
import ToggleErrorMsg from '../../components/ToggleErrorMsg/ToggleErrorMsg';
import Timer from '../../components/Timer/Timer'

// Override styles from provider clients if needed
import './meetingProviderClients.css'
import './joinMeeting.css';
import { useTheme } from '@emotion/react';

const JoinMeeting = ({ meetingProviderName, userMeetingId, localeCode, redirectUrl, redirectWindowName }) => {
    const { t } = useTranslation('translation', { keyPrefix: "joinMeeting" });
    const theme = useTheme();
    const TIME_TO_CANCEL_LISTENER_MS = toMillisecondsFromTimescale('sec', 10); 
    const POST_MEETING_PATH = `/connection/post-meeting/${meetingProviderName}/${userMeetingId}`;
    const postMeetingUrl = new URL(POST_MEETING_PATH, process.env.REACT_APP_URL);
    postMeetingUrl.searchParams.set('locale', localeCode);
    postMeetingUrl.searchParams.set('to', redirectUrl);
    postMeetingUrl.searchParams.set('in', redirectWindowName);
    const [meetingParams, setMeetingParams] = useState(null);
    const [accessMeetingToken, setAccessMeetingToken] = useState(sessionVariables.get('accessMeetingToken'));
    const [accessMeetingTokenExp, setAccessMeetingTokenExp] = useState();
    const [accessExpired, setAccessExpired] = useState(false);
    const [errorMap, setErrorMap] = useState({}); // types: [requesting | unkownProvider | joiningApp | joiningBrowser ]
    const meetingPlayerRef = useRef(null);
    const [meetingPlayer, _setMeetingPlayer] = useState(null);
    const setMeetingPlayer = (player) => {
        meetingPlayerRef.current = player;
        _setMeetingPlayer(player)
    };
    const meetingStatusRef = useRef(null);
    const [meetingStatus, _setMeetingStatus] = useState(null);
    const setMeetingStatus = (status) => {
        meetingStatusRef.current = status;
        _setMeetingStatus(status);
    }
    const styles = {
        divider: {
            paddingTop: '40px'
        },
        header: {
            fontWeight: 'bold'
        },
        expiracyContainer:{
            color: theme.palette.grey[500] //#88
        }
    }
    const getMeetingPlayer = (meetingProviderName) => {
        try{
            const meetingProvider = getMeetingProviderOrNull(meetingProviderName);
            if(meetingProvider){
                meetingProvider.setLocaleCode(localeCode);
                const meetingPlayer = new MeetingPlayer(meetingProvider);
                // communication with mainApp after user left the meeting
                // is triggered from and unload event due to redirect caused by meeting provider
                // meetingPlayer.setOnUserLeaveListener((e) => {
                //     const parentWindow = window.opener;
                //     const data = {
                //         meetingProviderName,
                //         userMeetingId,
                //         userMeetingStatus: 'user_left',
                //     }
                //     notifyMainAppWindow(data, parentWindow);
                // })
                meetingPlayer.setOnMeetingStatusListener((e) => {
                    if(e.meetingStatus === 'connected'){
                        const parentWindow = window.opener;
                        const data = {
                            meetingProviderName,
                            userMeetingId,
                            userMeetingStatus: 'user_joined',
                        }
                        notifyMainAppWindow(data, parentWindow);    
                    }
                    setMeetingStatus(e.meetingStatus)
                });
                setMeetingPlayer(meetingPlayer);
            }else{
                handleOnError('unknownProvider', new Error(`${meetingProviderName} is a not valid meeting provider`));
            }
        }catch(error){
            console.log(error)
            handleOnError('player', error);
        }
    }

    const getMeetingParams = async(signal=null) => {
        try{
            const meetingParamsRes = await getMeetingJoinParams(0, signal);
            const {
                meetingParams
            } = meetingParamsRes.data;
            if(!signal.aborted){
                setMeetingParams(meetingParams);
            }
        }catch(error){
            console.log(error)
            // catching no valid access meeting token between others
            if(!signal.aborted){
                handleOnError('requesting', error);
            }
        }
    }

    const handleOnTimer = () => {
        setAccessExpired(true);
    };
    const handleOnError = (type, error) => {
        setErrorMap(prev => ({ ...prev, [type]:{ isError: true, cause: error.message } }));
    };
    
    const handleOnRedirect = (e) => {
        e.preventDefault();
        const parentWindow = window.opener;
        // const data = {
        //     meetingProviderName,
        //     userMeetingId,
        //     closeConnection: true,
        // }
        // notifyMainAppWindow(data, parentWindow);
        if(parentWindow != null && redirectWindowName){
            window.open('', redirectWindowName);
        }else{
            window.location.assign(redirectUrl)
        }
    }
    
    const handleOnClose = (e) => {
        // e.preventDefault();
        // const parentWindow = window.opener;
        // const data = {
        //     meetingProviderName,
        //     userMeetingId,
        //     closeConnection: true,
        // }
        // notifyMainAppWindow(data, parentWindow);
        // if(parentWindow != null && redirectWindowName){
        //     window.open('', redirectWindowName);
        // }
        window.close();
    }

    useEffect(() => {
        getMeetingPlayer(meetingProviderName)
        if(accessMeetingToken == null){
            const receiveAccessMeetingToken = (e) => {
                if(e.origin === process.env.REACT_APP_MAIN_URL){
                    const accessMeetingToken = e.data.accessMeetingToken;
                    setAccessMeetingToken(accessMeetingToken);
                }
                return;
            }
            window.addEventListener('message', receiveAccessMeetingToken);
            const parentWindow = window.opener;
            console.log(window)
            console.log(parentWindow)
            if( parentWindow != null){
                const data = {
                    meetingProviderName,
                    userMeetingId,
                    isChildrenReady: true
                }
                notifyMainAppWindow(data, parentWindow);
                const handleOnLeave = async (e) => {
                    console.log('leaving')
                    e.preventDefault();
                    if(meetingPlayerRef.current){
                        await meetingPlayerRef.current.leave()
                    }
                    const parentWindow = window.opener;
                    const data = {
                        meetingProviderName,
                        userMeetingId,
                        extra: meetingStatusRef.current,
                        // Updated 04/11/24 since meetingStatusRef.current was disconnected
                        // ...(meetingStatusRef.current === 'connected' ? { userMeetingStatus: 'user_left' } : {}),
                        ...(meetingStatusRef.current != null ? { userMeetingStatus: 'user_left' } : {}),
                        closeConnection: true,
                    }
                    notifyMainAppWindow(data, parentWindow);
                }
                // Show warning when unloading 
                // const handleOnLeaveWillInMeetingWarning = async (e) => {
                //     e.preventDefault();
                //     e.returnValue = 'are you sure?'
                //     return 'are you sure?'
                // }
                // window.addEventListener('beforeunload', handleOnLeaveWillInMeetingWarning)
                window.addEventListener('unload', handleOnLeave); // 'unload' event is deprecated
                return(() => {
                    // window.removeEventListener('beforeunload', receiveAccessMeetingToken);
                    window.removeEventListener('unload', handleOnLeave); // 'unload' event is deprecated
                    window.removeEventListener('message', receiveAccessMeetingToken); 
                })
            }else{
                handleOnError('requesting', new Error('meeting access code was not received'))
            }
        }
    }, [])

    useEffect(() => {
        if(accessMeetingToken){
            const abortController = new AbortController()
            const signal = abortController.signal;
            const tokenExp = getTokenExp(accessMeetingToken);
            setAccessMeetingTokenExp(new Date(tokenExp));
            sessionVariables.set('accessMeetingToken', accessMeetingToken); // Could be removabled
            getMeetingParams(signal); 
            return(() => {
                abortController.abort()
                sessionVariables.remove('accessMeetingToken')
            })
        }else{
            const timeoutId = setTimeout(() => {
                handleOnError('requesting', new Error('meeting access code was not received'));
            }, TIME_TO_CANCEL_LISTENER_MS)
            return(() => {
                clearTimeout(timeoutId);
            })
        }
    }, [accessMeetingToken]);

    return (
        meetingStatus === 'connected' ?
            <HeaderOnMeeting meetingPlayer={meetingPlayer} postMeetingUrl={postMeetingUrl.toString()} />
            :
            <div>
                <HeaderSimple onRedirect={handleOnRedirect} />
                <Grid container direction='column' className='join-meeting-wrapper'>
                    { accessMeetingToken && meetingParams ?
                        <Grid item>
                            <Grid container direction='column' className='join-meeting-main-container'>
                                <Grid item align='center'>
                                    <Typography variant='body1' style={styles.header}>
                                        {t('doYouwantToJoinALiveMeeting')}
                                    </Typography>
                                </Grid>
                                    <Grid item>
                                        <Grid container className='join-meeting-buttons-container'>
                                            <Grid item className='join-meeting-button'>
                                                <JoinMeetingDesktopAppButton 
                                                    size="small"
                                                    meetingProviderName={meetingProviderName} 
                                                    meetingParams={meetingParams}
                                                    label={t('joinWithApp')}
                                                    disabled={accessExpired ||  errorMap['joiningApp']?.isError === true}
                                                    onError={(error) => handleOnError('joiningApp', error)}
                                                /> 
                                            </Grid>
                                            { meetingPlayer != null &&
                                                <Grid item className='join-meeting-button'>
                                                    <JoinMeetingButton 
                                                        variant='contained' 
                                                        size="small"
                                                        meetingPlayer={meetingPlayer} 
                                                        meetingParams={meetingParams}
                                                        postMeetingUrl={postMeetingUrl.toString()}
                                                        label={t('join')} 
                                                        disabled={accessExpired || errorMap['joiningBrowser']?.isError === true}
                                                        onError={(error) => handleOnError('joiningBrowser', error)}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                {accessMeetingTokenExp &&
                                    <Grid item>
                                        <Grid container style={styles.expiracyContainer}>
                                            <Grid item>
                                                <Typography variant='body2'>
                                                    {textTransform('title', t('accessLinkExpiresIn'))}
                                                </Typography>
                                            </Grid>
                                            <Grid item className='join-meeting-expiracy-timer'>
                                                <Timer finalDate={accessMeetingTokenExp} onTime={handleOnTimer} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item>
                                    <Grid className='join-meeting-expiracy-error-container' container direction='column'>
                                        <Grid item>
                                            <ToggleErrorMsg
                                                className={`join-meeting-expiracy-error-msg-container ${accessExpired ? 'show' : ''}`} 
                                                msg={
                                                    `${textTransform('title', t('accessExpired'))}.\n${textTransform('title', t('openAgain'))}`
                                                }
                                            />
                                        </Grid>
                                        <Grid item className={`join-meeting-redirect-button-container ${accessExpired ? 'show' : ''}`}> 
                                            <Button onClick={handleOnClose} variant='contained'>
                                                {t('backToEvent')}
                                            </Button>   
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {errorMap.joiningApp?.isError === true &&
                                    <>
                                        <Divider flexItem variant='middle' style={styles.divider}/>
                                        <Grid item className='join-meeting-instruction-wrapper'>
                                            <JoinMeetingInstructions meetingProviderName={meetingProviderName} meetingParams={meetingParams} />
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    :
                        <Grid item>
                            {errorMap.unknowProvider?.isError === true || errorMap.requesting?.isError === true ?
                                <JoinMeetingError
                                    meetingProviderName={meetingProviderName}
                                    userMeetingId={userMeetingId}
                                    onRedirect={handleOnClose}
                                    cause={ errorMap.requesting?.cause || errorMap.unknowProvider?.cause }/>
                            :
                                <LoadingComponent visibleElements='circle' />
                            }
                        </Grid>
                    }
                </Grid>
            </div>
    )
}

export default JoinMeeting
/*
* This module contains helpers related to window communication with the main app 
*/

/**
 * Notice that can cause cross-origin errors if targetWindow
 * does not belong to 'meeting.qxinstitute.com' domain.
 * To solve this we assing to document.domain 'qxinstitute.com'
 * to allow subdomain communication.
 * @param {Object} data 
 * @param {Window} targetWindow 
 */
const notifyMainAppWindow = async( data , targetWindow) => {
    targetWindow.postMessage( data, process.env.REACT_APP_MAIN_URL);
}

// DEPRECATED
// Fails. Opener is not accessible if it comes from a different origin
// const isMainAppOpener = () => {
//     return window.opener?.location.origin === process.env.REACT_APP_MAIN_URL
// }

export{
    notifyMainAppWindow,
}
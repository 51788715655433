import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { textTransform } from '../../aux/aux';
import { aesMeetingDecipher } from '../../aux/cryptoHelpers';
import { getMeetingProviderClientDownloadUrlOrNull, isAValidMeetingProvider } from '../../aux/meetingProviderHelpers'
import { useTheme } from '@emotion/react';
import './joinMeetingInstructions.css'

const JoinMeetingInstructions = ({ meetingProviderName, meetingParams }) => {
    const theme = useTheme();
    const { t } = useTranslation('translation', { keyPrefix: "joinMeetingInstructions"});
    const [isError, setIsError] = useState(() => isAValidMeetingProvider(meetingProviderName) !== true);
    const styles = {
        header:{
            fontWeight: 'bold'
        },
        infoContainer:{
            borderColor: theme.palette.primary.main
        },
        textLink:{
            color:theme.palette.primary.main,
            textDecorationColor: theme.palette.primary.main
        }
    }
    const getMeetingProviderPassword = () => {
        try{
            return aesMeetingDecipher(meetingParams.cipheredMeetingPassword)
        }catch(error){
            setIsError(true)
        }
    }

  return (
    isError ?
        <Grid item>
            
        </Grid>
    :
        <Grid container direction={'column'} alignItems='center'>
            <Grid item>
                <Typography variant='body1' style={styles.header}>
                    {t("doYouHaveAnyProblemsConnecting")}
                </Typography>
            </Grid>
            <Grid item className='join-meeting-instruction-info-item'>
                <Typography variant='body2'>
                    {`${t("translation:1")}. ${textTransform('title', t("installMeetingProviderClient", { provider: textTransform('title', meetingProviderName) }))}`}
                </Typography>
            </Grid>
            <Grid item className='join-meeting-instruction-info-item'>
                <Typography 
                    component='a' 
                    variant='body2'
                    className='join-meeting-instructions-text-link' 
                    href={getMeetingProviderClientDownloadUrlOrNull(meetingProviderName)} 
                    target='_blank'
                    rel="noopener noreferrer"
                    style={styles.textLink}
                >
                    {textTransform('title', getMeetingProviderClientDownloadUrlOrNull(meetingProviderName))}
                </Typography>
            </Grid>
            <Grid item className='join-meeting-instruction-info-item'>
                <Typography
                    variant='body2'
                >
                    {`${t("translation:2")}. ${textTransform('title', t("joinWithThisCredentials"))}`}
                </Typography>
            </Grid>
            <Grid item className='join-meeting-instruction-info-item'>
                <Grid container  className='join-meeting-instruction-info-container' style={styles.infoContainer}>
                    <Grid item className='join-meeting-instruction-info-labels-container'>
                        <Typography variant='body2'>
                            {`${textTransform('title', t("meetingId"))}:`}
                        </Typography>
                        <Typography variant='body2'>
                            {`${textTransform('title', t("accessCode"))}:`}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2'>
                            {meetingParams.meetingProviderMeetingId}
                        </Typography>
                        <Typography variant='body2'>
                            {getMeetingProviderPassword()}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className='join-meeting-instruction-warning-container join-meeting-instruction-info-item'>
                <Typography variant='body2'>
                    {`${t("translation:3")}. ${textTransform('title', t("doNotShareTheMeetingInfo"))}`}
                </Typography>
            </Grid>
        </Grid>
  )
}

export default JoinMeetingInstructions
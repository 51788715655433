import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { forwardRef } from 'react';
import { Box, Paper } from "@mui/material";

// Img and graphics
import CloseIcon from '../../img/close-fill.svg';

// Style
import './popUpModal.css'

const PopUpModal = ({showModal, onClickClose, onOutsideClick, height, width, isDialog=false, ...props}, ref) => {
    const modalRef = useRef(null);
    const styles = {
        wrapper: {
            // animation: showModal ? 'fade-in 0.3s' : 'fade-in 0.3 reverse'
            ...props.wrapperStyle
        },
        paper: {
            height,
            width,
            ...props.style
        }
    }

    useEffect(() => {
        if(onOutsideClick){
            if(modalRef.current){
                const closeOnOutsideClick = (e) => {
                    if(e.target === modalRef.current){
                        onOutsideClick();
                    }
                }
                window.addEventListener('click', closeOnOutsideClick)
                return( () => {
                    window.removeEventListener('click', closeOnOutsideClick)
                })
            }
        }
    },[])

    return ReactDOM.createPortal(
        <Box ref={modalRef} className={`pop-up-main ${showModal ? 'pop-up-main-visible' : 'pop-up-main-hidden'}`} style={ styles.wrapper }>
            <Paper ref={ref} className='pop-up-modal' style={ styles.paper }>
                {!isDialog &&
                    <CloseIcon className='pop-up-modal-close-icon' onClick={onClickClose} height={25}/>
                }
                {props.children}
            </Paper>
        </Box>
        , document.body
    )
}

export default forwardRef(PopUpModal)
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import InjectAxiosInterceptors from './components/InjectAxiosInterceptors/InjectAxiosInterceptors';
import { clearSessionVariables } from './aux/sessionHelpers';

import PostMeeting from './pages/postMeeting/PostMeeting';
import JoinMeeting from './pages/joinMeeting/JoinMeeting';
import PageNotFound from './pages/pageNotFound/PageNotFound';
import LoadingComponent from './components/LoadingComponent/LoadingComponent';

import { pink, indigo, red, orange, blue, green } from '@mui/material/colors'
import './App.css';

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: [
        // 'Roboto',
        // 'raleway',
        // 'Nunito Sans',
        'Noto Sans',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
      ].join(','),
    },
    palette:{
      primary: indigo,
      secondary: pink,
      error: red,
      warning: orange,
      info: blue,
      success: green
    },
  })
  useEffect(()=>{
    // Set the main domain to allow cross window communication
    // between them. This is deprecated but it still works
    // document.domain = process.env.REACT_APP_MAIN_APP_DOMAIN;
    const clearSession = (e) => {
      clearSessionVariables()
    }
    window.addEventListener( 'beforeunload', clearSession )
    return(() => {
      window.removeEventListener( 'beforeunload', clearSession );
    })
  },[])

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <InjectAxiosInterceptors />
          <div className='app'>
            <Switch>
              <Route path='/connection/:provider/:userMeetingId' exact render={(props) => {
                const query = new URLSearchParams(props.location.search);
                let componentToRender;
                const fromUrl = query.get('from');
                const openerName = query.get('in');
                if(fromUrl != null && fromUrl.startsWith(process.env.REACT_APP_MAIN_URL)){
                  componentToRender = <JoinMeeting meetingProviderName={props.match.params.provider} userMeetingId={props.match.params.userMeetingId} localeCode={query.get('locale')} redirectUrl={fromUrl} redirectWindowName={openerName} />
                }else{
                  componentToRender = <Redirect to={'/404'}/>
                }
                return( componentToRender );
              }}/>
              <Route path='/connection/post-meeting/:provider/:userMeetingId' exact render={(props) => {
                  const query = new URLSearchParams(props.location.search);
                  const toUrl = query.get('to');
                  const openerName = query.get('in');
                  let componentToRender;
                  if(toUrl != null && toUrl.startsWith(process.env.REACT_APP_MAIN_URL)){
                    componentToRender = <PostMeeting meetingProviderName={props.match.params.provider} userMeetingId={props.match.params.userMeetingId} localeCode={query.get('locale')} redirectUrl={toUrl} redirectWindowName={openerName} />
                  }else{
                    componentToRender = <Redirect to={'/404'}/>
                  }
                  return( componentToRender ); 
                }}
              />
              <Route path='/*' render={(props) =>(<PageNotFound />)}/>
            </Switch>
          </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;

import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button } from '@mui/material';
import { textTransform, textTruncate, toMillisecondsFromTimescale } from '../../aux/aux';

import HeaderSimple from '../../components/HeaderSimple/HeaderSimple';
import Timer from '../../components/Timer/Timer';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import congrats from '../../lotties/congrats.json';
import './postMeeting.css';

const PostMeeting = ({ meetingProviderName, userMeetingId, localeCode, redirectUrl, redirectWindowName}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'postMeeting' });
    const animationContainerRef = useRef(null);
    const animationRef = useRef(null);
    const handleOnRedirect = (e) => {
        try{
            e.preventDefault();
            const parentWindow = window.opener;
            if(parentWindow != null && redirectWindowName){
                window.open('', redirectWindowName);
                window.close();
            }else{
                window.location.assign(redirectUrl)
            }
        }catch(error){
            console.log(error)
        }
    }

    const handleOnTime = () => {
        window.close();
    }

    useEffect(() => {
        if(animationContainerRef.current){
            animationRef.current = lottie.loadAnimation({
                container: animationContainerRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: congrats
            });
            return(() => {
                animationRef.current.stop(); animationRef.current?.destroy()
            })
        }
    },[])
    
    return (
    <>
        <HeaderSimple onRedirect={handleOnRedirect} />
        <Grid container direction='column' className='post-meeting-wrapper'>
            <Grid item>
                <div ref={animationContainerRef} className='post-meeting-animator-container'/>
            </Grid>
            <Grid item>
                <Typography variant='h4' align='center'>
                    {textTransform('title', t('thatWasAwesome'))}
                </Typography>
            </Grid>
            <Grid item className='post-meeting-guidance'>
                <Typography variant='body1' style={{whiteSpace:'pre-line', textAlign:'center'}}>
                    {
                        `${textTransform('title', t('thankForAttending'))}`
                        +`\n${textTransform('title', t('seeYouSoon'))}`
                    }
                </Typography>
            </Grid>
            <Grid item className='post-meeting-button'>
                <Button onClick={handleOnRedirect} variant='text'>
                    {t('backToMainApp')}
                </Button>   
            </Grid>
        </Grid>
    </>
  )
}

export default PostMeeting
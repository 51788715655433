/**
 * This module contains useful helpers to deal with session variables on the browser
 */
let storage = sessionStorage;

const variableNames = {
    accessMeetingToken: 'accessMeetingToken',
    userLocaleCode: 'userLocaleCode'
};

function get(name){
    return storage.getItem(variableNames[name]);
};

function set(name, value){
    if(name in variableNames){
        storage.setItem(variableNames[name], value);
    }
};

function remove(name){
    if(name in variableNames){
        storage.removeItem(variableNames[name]);
    }
};

function hasOwnProperty(name){
    if(name in variableNames){
        return storage.hasOwnProperty(variableNames[name]);
    }else{
        return false;   
    }
};

const sessionVariables = {
    get,
    set,
    remove,
    hasOwnProperty
};

const clearSessionVariables = () => {
    storage.clear();
}

export {
    sessionVariables,
    clearSessionVariables,
};

import { useEffect } from 'react';
import { useState } from 'react';
import { promisingTimeout } from '../../aux/aux';
import { aesMeetingDecipher } from '../../aux/cryptoHelpers';
import { getMeetingSignature, getMeetingJoinParams } from '../../services/meetingServices';

import ButtonLoading from '../ButtonLoading/ButtonLoading';

import './joinMeetingButton.css';

const JoinMeetingButton = ({ meetingPlayer, meetingParams, postMeetingUrl, label='join the meeting', onJoin=()=>{}, onError=()=>{}, ...props}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleJoinMeeting = async (e) => {
        try{
            setIsLoading(true)
            if(meetingPlayer.status !== 'connecting' && meetingPlayer.status !== 'connected'){
                const signatureRes = await getMeetingSignature(1000);
                const {
                    meetingSignature,
                } = signatureRes.data;
                const userEmail = aesMeetingDecipher(meetingParams.cipheredUserEmail)
                const passWord = aesMeetingDecipher(meetingParams.cipheredMeetingPassword)
                const playerParams = {
                    meetingNumber: meetingParams.meetingProviderMeetingId,
                    userName: `${meetingParams.userFirstName} ${meetingParams.userLastName}${meetingParams.lowPriorityMeeting ? ' (R)' : ''}`,
                    registrantToken: meetingParams.registrationToken,
                    leaveUrl: postMeetingUrl,
                    userEmail,
                    passWord,
                }
                await meetingPlayer.join({ signature: meetingSignature, params: playerParams} );
                meetingPlayer.show();
            }else{
                await promisingTimeout(1000);
                meetingPlayer.show();
            }
            onJoin();
        }catch(error){
            console.log(error)
            onError(error);
        }finally{
            setIsLoading(false)
        }
    }

    
    return (
        <ButtonLoading 
            onClick={handleJoinMeeting} 
            isLoading={isLoading || props.isLoading}
            disabled={props.disabled}
            label={label} 
            {...props}
        />
    )
}

export default JoinMeetingButton
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Typography, Button } from '@mui/material'
import { textTransform, textTruncate } from '../../aux/aux'
import { useTheme } from '@emotion/react'
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import noConnection from '../../lotties/noConnection_200x200.json';
import { sessionVariables } from '../../aux/sessionHelpers'
import { notifyMainAppWindow } from '../../aux/mainAppCommunicationHelpers'
import './joinMeetingError.css'

const JoinMeetingError = ({ meetingProviderName, userMeetingId, onRedirect, cause='' }) => {
    const { t } = useTranslation('translation', { keyPrefix: "joinMeetingError" });
    const theme = useTheme()
    const animationContainerRef = useRef(null);
    const animationRef = useRef(null);
    const handleOnRedirect = (e) => {
        onRedirect(e);
    }
    const styles = {
        errorCause: {
            backgroundColor: theme.palette.grey[50]
        }
    }
    useEffect(() => {
        const parentWindow = window.opener;
        const data = {
            meetingProviderName,
            userMeetingId,
            meetingError: cause,
        }
        notifyMainAppWindow(data, parentWindow);
    },[])

    useEffect(() => {
        if(animationContainerRef.current){
            animationRef.current = lottie.loadAnimation({
                container: animationContainerRef.current,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: noConnection
            });
            return(() => {animationRef.current.stop(); animationRef.current?.destroy()})
        }
    },[])
    return (
        <Grid container className='join-meeting-error-main-container' direction='column'>
            <Grid item>
                <div ref={animationContainerRef} />
            </Grid>
            <Grid item>
                <Typography variant='h4'>
                    { textTransform('title', t("uups")) }
                </Typography>
            </Grid>
            <Grid item className='join-meeting-error-cause-container'>
                <Typography className='join-meeting-error-cause' variant='body2' style={styles.errorCause}>
                    { textTruncate(textTransform('title', cause), 100) }
                </Typography>
            </Grid>
            <Grid item className='join-meeting-error-guidance'>
                <Typography variant='body1' style={{whiteSpace:'pre-line', textAlign:'center'}}>
                    { `${textTransform('title', t("cannotJoinMeeting"))}` 
                     +`\n${textTransform('title', t("tryToOpenAgain"))}`
                    }
                </Typography>
            </Grid>
            <Grid item className='join-meeting-error-button'>
                <Button onClick={handleOnRedirect} variant='contained'>
                    {t("backToEvent")}
                </Button>   
            </Grid>
        </Grid>
  )
}

export default JoinMeetingError
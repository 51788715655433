import axios from 'axios';
import { isTokenActive } from './authHelpers';
import { sessionVariables } from './sessionHelpers';

const BASE_URL = `${process.env.REACT_APP_API_BASEPATH}:${process.env.REACT_APP_API_PORT}/${process.env.REACT_APP_API_MEETING_PATH}/`; 

/**
 * Creates a blank axios instance
 */
const publicThirdPartyRequest = axios.create();

/**
 * Creates an axios instance pointing out our API
 */
const publicRequest = axios.create({
    baseURL : BASE_URL,
});

let interceptorResponseErrorHandler,
    forceUserToLogin,
    history
const setHistoryForInterceptors = (historyFromHook) => {history = historyFromHook};
const setUpInterceptorsAPIResponseError = (callback) => { interceptorResponseErrorHandler = callback};
const setForceUserToLoginForInterceptors = (fn) => {forceUserToLogin = fn};

/**
 * Creates an axios instance with an user configuration
 */
function createAuthRequest(){
    const userRequest = axios.create({
        baseURL: BASE_URL,
        // headers: {
        //     authorization : `Bearer ${sessionVariables.get('accessMeetingToken')}`,
        // }
    });
    userRequest.interceptors.request.use(
        async (config) => {
            const accessToken = sessionVariables.get('accessMeetingToken');
            if(!isTokenActive(accessToken)){
                return Promise.reject(new Error('no valid access meeting token'))
            }
            config.headers['authorization'] = `Bearer ${accessToken}`;
            return config
        }, (error) => {
            return Promise.reject(error);
        }
    )
    // Added ON 09/25/22
    userRequest.interceptors.response.use(
        (response) => { 
            return response
        }, (error) => {
            interceptorResponseErrorHandler(error);
            return Promise.reject(error);
        }
    )
    return userRequest;
}


/**
 * Perform a request and stop the thread at least a minimum set
 */
const minimumLoadingTime = 2000; //ms
async function requestMinimumTimeoutWrapper( request, requestParameters={}, minimumTimeout = minimumLoadingTime, signal=null){
    try{
        const date = new Date()
        const startLoadingTime = date.getUTCMilliseconds()
        const res = await request(requestParameters, signal);
        const pendingTime = minimumTimeout - (date.getUTCMilliseconds() - startLoadingTime)
        if(pendingTime > 0 ) await new Promise((resolve) => { setTimeout(resolve, pendingTime)});
        return res;
    }catch(error){
        throw error
    }
}

export { publicThirdPartyRequest, publicRequest, createAuthRequest,
     requestMinimumTimeoutWrapper, setHistoryForInterceptors, setUpInterceptorsAPIResponseError,
     setForceUserToLoginForInterceptors }
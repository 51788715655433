import { createAuthRequest } from "./requestMethods"

/**
 * This module contains meeting-related queries
 */
const getMeetingSignatureFn = async ( params, signal ) => {
    const {} = params
    const userRequest = createAuthRequest();
    return userRequest.get(`/signature`, {signal: signal})
}
const getMeetingJoinParamsFn = async ( params, signal ) => {
    const {} = params
    const userRequest = createAuthRequest();
    return userRequest.get(`/meeting-join-params`, {signal: signal})
}

export{
    getMeetingSignatureFn,
    getMeetingJoinParamsFn
}
/**
 * This module contains an interface to interact with ZoomSDK
 */
import { promisify } from 'es6-promisify';

import { ZoomMtg } from '@zoomus/websdk';
import { promisingTimeout } from './aux';

class ZoomMeetingProvider {
    _VERSION = '3.5.2';//'2.9.5';
    _meeting;
    provider = 'zoom';
    localeCode;
    meetingStatusValues = {
        1: 'connecting',
        2: 'connected',
        3: 'disconnected',
        4: 'reconnecting'
    }
    constructor(){
        try{
            this._meeting = ZoomMtg;
            this._meeting.setZoomJSLib(`https://source.zoom.us/${this._VERSION}/lib`, '/av');
            this._meeting.preLoadWasm();
            this._meeting.prepareWebSDK();
            this._setListenerToOverrideStyles();
            // this._appendStyles();
            // loads language files, also passes any error messages to the ui
            // this._meeting.i18n.load(localeCode);
            // this._meeting.i18n.reload(localeCode);
        }catch(error){
            console.log(error)
            throw new Error(`zoom meeting provider could not be setup. Error: ${error}`);
       } 
    }
    // Appended in index.html
    // _appendStyles(){
    //     try{
    //         const head = document.head;
    //         for(let cssFile of ['bootstrap', 'react-select']){
    //             let link = document.createElement('link');
    //             link.type = 'text/css';
    //             link.rel = 'stylesheet';
    //             link.href = `https://source.zoom.us/${this._VERSION}/css/${cssFile}.css`
    //             head.insertBefore(link, head[0]);
    //         }
    //     }catch(err){
    //         console.log(err);
    //         throw err
    //     }
    // }

    setLocaleCode(localeCode){
        try{
            // loads language files, also passes any error messages to the ui
            if(localeCode){
                this.localeCode  = localeCode;
                this._meeting.i18n.load(localeCode);
                this._meeting.i18n.reload(localeCode);
            }else{
                console.info('zoom cannot set null as localCode')
            }
        }catch(error){
            throw new Error(`zoom meeting cannot set localeCode to ${localeCode}. Error: ${error}`);
        }
    }

    show(){
        document.getElementById('zmmtg-root').style.display = 'block'
    }

    hide(){
        document.getElementById('zmmtg-root').style.display = 'none'
    }

    async init({ params }){
        try{
            return promisify(this._meeting.init)({
                leaveUrl: params.leaveUrl,
                disableInvite: true,
                disablePreview: true, // disables preview video and audio room
                meetingInfo: ['topic', 'host'],
                disableRecord: true,
                enableFullHD: true, // requires backend support
                // onRetryCallback:
                // enableHD:true, // default true but requires backend support
            });
        }catch(error){
            console.log(error);
            throw error
        }
        // return promisify(this._meeting.init({
        //     leaverUrl: params.leaverUrl
        // }));
    };

    async join({ signature, params }){
        try{
            return promisify(this._meeting.join)({
                meetingNumber: params.meetingNumber,
                userName: params.userName,
                userEmail: params.userEmail,
                passWord: params.passWord,
                tk: params.registrantToken,
                signature: signature,
                sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY,
            });
        }catch(error){
            console.log(error)
            throw error
        }
        // return promisify(this._meeting.join({
        //     meetingNumber: params.meetingNumber,
        //     userName: params.userName,
        //     userEmail: params.userEmail,
        //     passWord: params.passWord,
        //     tk: params.registrantToken,
        //     signature: signature,
        //     sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY,
        // }));
    }
    async leave(onSuccess, onError){
        try{
            const args = {
                confirm: false, // no show confirmation
                success: onSuccess, // it is triggered before redirect
                error: onError,
            }
            return promisify(this._meeting.leaveMeeting)(args);
        }catch(error){
            throw error
        }
    }

    _setListenerToOverrideStyles(){
        this._setInMeetingListener('onUserJoin', (e) => {
            // This style change has been set via css file
            // pages/joinMeeting/meetingProviderClients.css
            // const intervalId = setInterval(() => {
            //     const leftInfoContainer = document.getElementsByClassName('meeting-info-container--left-side');
            //     if(leftInfoContainer.length > 0){
            //         leftInfoContainer[0].style.top = '60px'
            //         clearInterval(intervalId)
            //     }
            // },100);
        });
        this._setInMeetingListener('onMeetingStatus', (e) => {
            if(e && e.meetingStatus){
                const meetingStatus = this.meetingStatusValues[e.meetingStatus];
                if(meetingStatus === 'connecting'){
                    // Update UI return button from zoom clientsdk
                    // return button is defined to return to the fall back url given to zoom
                    // at this stage the user has not connected the meeting yet so we want them
                    // to come back to the join buttons menu instead to do so we refresh the page
                    const intervalId = setInterval(() => {
                        const waitingLeaveBtnId = 'waiting-leave-btn';
                        const elementsByClass = document.getElementsByClassName('leaveWaitingBtn');
                        if(elementsByClass.length > 0){
                            const leaveWaitingBtn = elementsByClass[0];
                            if(leaveWaitingBtn.getAttribute('id') !== waitingLeaveBtnId){
                                // cloneNode copy all attributes except eventListeners
                                // this prevent the behaviour set by zoom
                                const newButton = leaveWaitingBtn.cloneNode(true);
                                // id is set to prevent race condition causing different
                                // instances of the event listener
                                newButton.setAttribute('id', waitingLeaveBtnId);
                                newButton.addEventListener('click', () => {
                                    this.hide()
                                    // document.getElementById('zmmtg-root').style.display = 'none'
                                    // window.location.reload()
                                })
                                leaveWaitingBtn.replaceWith(newButton);
                            }
                            clearInterval(intervalId)
                        }
                    },100);
                    setTimeout(() => {clearInterval(intervalId)}, 2000);
                }
            }
        })
    }

    setOnUserJoinListener(callback){
        this._setInMeetingListener('onUserJoin', callback);
    }
    setOnUserLeaveListener(callback){
        this._setInMeetingListener('onUserLeave', callback);
    }
    setOnMeetingStatusListener(callback){
        // callback returns { meetingStatus: meetingStatusId }
        this._setInMeetingListener('onMeetingStatus', (e) => {
            if(e && e.meetingStatus){
                const meetingStatus = this.meetingStatusValues[e.meetingStatus];
                callback({ meetingStatus })
            }
        });
    }
    setOnUserIsInWaitingRoom(callback){
        this._setInMeetingListener('onUserIsInWaitingRoom', callback)
    }
    _setInMeetingListener(eventType, callback){
        // "onUserJoin" | "onUserLeave" | "onUserIsInWaitingRoom" | "onMeetingStatus" | "onPreviewPannel| receiveSharingChannelReady" | "onReceiveTranscriptionMsg" | "onReceiveTranslateMsg" | "onAudioQos" | "onVideoQos"
        this._meeting.inMeetingServiceListener(eventType, callback)
    }
};

let zoomMeetingProvider;

const getZoomMeetingProvider = () => {
    if(zoomMeetingProvider == null){
        zoomMeetingProvider = new ZoomMeetingProvider()
    }
    return zoomMeetingProvider;
}

export{
    getZoomMeetingProvider
}
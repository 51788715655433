/**
 * This module contains all the helper functions and classes to handle live meetings
 */
class MeetingPlayer {
    _validProvider = ['zoom'];
    provider;
    player;
    status;
    constructor ( meetingProvider ){
        if(this._validProvider.includes(meetingProvider.provider)){
            this.player = meetingProvider;
            this.provider = meetingProvider.provider;
            this.setOnMeetingStatusListener((e) => {
                this.status = e.meetingStatus;
            })
        }else{
            throw new Error(`invalid meeting provider. Must be one of ${this._validProvider.join(',')}`)
        }
    };

    async _init( params ){
        switch(this.provider){
            case 'zoom':
                this.player.init( params )
                break;
            default:
        }
    };

    show(){
        this.player.show();
    }

    hide(){
        this.player.hide();
    }

    async join( params ){
        await this._init( params );
        this.player.join( params );
    }

    async leave(){
        this.player.leave();
    }

    setOnUserJoinListener(callback){
        this.player.setOnUserJoinListener(callback);
    }
    setOnUserLeaveListener(callback){
        this.player.setOnUserLeaveListener(callback);
    }
    setOnMeetingStatusListener(callback){
        this.player.setOnMeetingStatusListener(callback);
    }
    setOnUserIsInWaitingRoom(callback){
        this.player.setOnUserIsInWaitingRoom(callback);
    }
};

export{
    MeetingPlayer,
}
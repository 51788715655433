/**
 * This module works as an interface for different meeting providers such as Zoom
 */
import { getZoomMeetingProvider } from "./zoomMeetingProvider";

const meetingProviderMap = {
    zoom:{
        downloadUrl: 'https://zoom.us/support/download',
        getMeetingProvider: getZoomMeetingProvider
    }
};

const getMeetingProviderOrNull = (providerName) => {
    const provider = meetingProviderMap[providerName] || {}
    const meetingProvider = provider.hasOwnProperty('getMeetingProvider') ? provider.getMeetingProvider() : null;
    return meetingProvider;
};
const getMeetingProviderClientDownloadUrlOrNull = (providerName) => {
    const provider = meetingProviderMap[providerName] || {}
    const clientDownloadUrl = provider.hasOwnProperty('downloadUrl') ? provider.downloadUrl : null;
    return clientDownloadUrl;
}
const isAValidMeetingProvider = (providerName) => {
    return meetingProviderMap.hasOwnProperty(providerName);
}
export{
    getMeetingProviderOrNull,
    getMeetingProviderClientDownloadUrlOrNull,
    isAValidMeetingProvider,
}
/*
    This component contains helper functions

*/
function promisingTimeout( time, callback=null ){
    let promise
    if(callback){
        promise = new Promise((resolve) => { 
            setTimeout(() => {
                callback();
                resolve()
            }, time);
        });
    }else{
        promise = new Promise((resolve) => { setTimeout(resolve, time)});
    }
    return promise;
}
function textTransform(option, text){
    let transformedText;
    if(typeof(text) === 'string'){
        let textArray = text.split(' ')
        switch(option){
            case 'capitalize':
                transformedText = textArray.map( word => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                }).join(' ');
                break;
            case 'title':
                transformedText = text.charAt(0).toUpperCase() + text.slice(1);
                break;
            default:
                transformedText = text;
        }
    }
    return transformedText
}
function textTruncate(string, length, ending='...'){
    let truncatedText;
    if(string.length > length){
        truncatedText = string.substring(0, length - ending.length) + ending;
    }else{
        truncatedText = string;
    }
    return truncatedText
}
/**
 * Safely returns a property value from a nested object based on a propPathString given 
 * @param {*} nestedObject Example {address:{city:{cp:['28002', '28882']}}}
 * @param {string} propPathString string of object props nested by '.' Example: 'address.city.cp[0]'
 *      if '' returns the whole object
 *      if the property does not exist it returns undefined
 * @returns 
 */
function getFromSafeObject(nestedObject, propPathString){
    const pathArray = [];
    propPathString.replaceAll(/[\[\]]/g, '.').split('.').forEach(prop => {if(prop) pathArray.push(prop.trim())});
    return pathArray.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObject)
}
function toTimescaleFromMilliseconds(timescale, milliseconds){
    let time;
    switch(timescale){
        case('sec'):
            time = milliseconds / 1000;
            break;
        case('min'):
            time = milliseconds / (60 * 1000);
            break;
        case('hour'):
            time = milliseconds / (60 * 60 * 1000);
            break;
        case('day'):
            time = milliseconds / (24 * 60 * 60 * 1000);
            break;
        case('week'):
            time = milliseconds / (7 * 24 * 60 * 60 * 1000);
            break;
        default:
            throw new Error('timescale is unknown');
    }
    return time;
}

function toMillisecondsFromTimescale(timescale, time){
    let milliseconds;
    switch(timescale){
        case('sec'):
            milliseconds = time * 1000;
            break;
        case('min'):
            milliseconds = time * (60 * 1000);
            break;
        case('hour'):
            milliseconds = time * (60 * 60 * 1000);
            break;
        case('day'):
            milliseconds = time * (24 * 60 * 60 * 1000);
            break;
        case('week'):
            milliseconds = time * (7 * 24 * 60 * 60 * 1000);
            break;
        default:
            throw new Error('timescale is unknown');
    }
    return milliseconds;
}

export{
    promisingTimeout,
    textTransform,
    textTruncate,
    getFromSafeObject,
    toMillisecondsFromTimescale,
    toTimescaleFromMilliseconds
}